.main {
    font-family: 'Oranienbaum', serif;
}

.menuBars {
    position: fixed;
    z-index: 4;
    width: 25px;
    left: 10px;
    top: 7px;
    cursor: pointer;
    background-color: black;
    padding: 7.5px;
    border-radius: 15px;
}

.header {
    position: absolute;
    z-index: 5;
    top: 7px;
    background-color: black;
    border-radius: 15px;
    color: #D8AE5E;
    width: 72%;
    margin-left: 30px;
    height: 60px;
    font-size: larger;
    font-weight: 900;
}

.header img {
    position: relative;
    width: 100px;
    top: -40px
}

.header>nav>ul {
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    width: 90%;
}


.header li {
    cursor: pointer;

}

.header a {
    text-decoration: none;
    color: #D8AE5E;
}

.closeOut {
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid gray;
    box-shadow: 1px 0 33px gray;
    font-size: larger;
    cursor: pointer;
    color: #D8AE5E;
    padding: 0 10px;
}

.navSideBar>ul {
    list-style: none;
}

.navSideBar li {
    font-size: larger;
    padding-bottom: 10px;
    cursor: pointer;

}

.navSideBar a,
.navSideBar Link {
    text-decoration: none;
    color: #D8AE5E;
}

.navSideBar li:hover {
    text-shadow: 1px 0 27px gray;
}


.mobileLogo {
    width: 50px;
    position: fixed;
    z-index: 6;
    right: 10px;
}

.graphic {
    position: relative;
    right: 175px;

    opacity: .5;
}

@media only screen and (max-width: 600px) {

    .header {
        display: none
    }
}

@media only screen and (max-width: 599px) {

    .main {
        display: flex;
    }
}